<template>
  <tr>
    <td class="width">{{ item.name }}</td>
    <td class="width">{{ item.quantity }}</td>
    <td>
      <input
          v-model="totalQty"
          type="number"
          class="form-control text-center"
          :min="0"
      >
      <p class="text-danger mb-0" v-if="item.errorMessage">
        {{ item.errorMessage }}
      </p>
    </td>
  </tr>
</template>

<script setup>
import {computed, inject} from "vue";

const props = defineProps(['item'])
const formData = inject("formData")

const totalQty = computed(() => {
  const totalQuantity = (props.item.quantity * formData.value.production_quantity).toFixed(4);
  return props.item.total_quantity = totalQuantity
})

</script>

<style scoped>
.width {
  width: 300px;
}
</style>
